<template>
  <div id="SignUp">
    <div class="ivv-layer" v-if="newYearLuckyInfo">
      <div class="content years-cont">
        <div class="title">2025年蜜蛋年会</div>
        <div class="y-list">
          <span>年会基金券金额:</span>
          <div class="li">
            <!-- <p><em>{{newYearLuckyInfo.fundMoney}}</em>元({{newYearLuckyInfo.state?'已激活':'未激活'}})</p> -->
            <p><em>{{newYearLuckyInfo.fundMoney}}</em>元</p>
            <p>{{newYearLuckyInfo.fundMoneyStr}}</p>
          </div>
        </div>
        <div class="y-list">
          <span>年会抽奖券:</span>
          <div class="li">
            <p><em>{{newYearLuckyInfo.prizeDrawNum}}</em>张</p>
            <p>{{newYearLuckyInfo.prizeDrawNumStr}}</p>
          </div>
        </div>
        <div class="y-list" v-if="newYearLuckyInfo.mixedAccountPrizeList.length > 0">
          <span>采购券充值累积获赠:</span>
          <div class="li">
            <p v-for="(item,index) in newYearLuckyInfo.mixedAccountPrizeList" :key="index">{{item.prizeName}}<em class="rig">{{item.num}}</em>套</p>
          </div>
        </div>
        <div class="hint">注:年会基金券/年会抽奖券均须到现场激活</div>
      </div>
    </div>
  </div>
</template>

<script>

import { replaceAppToken, deliveryMessage } from "../../../utils/appUtil";

export default {
  name: "SignUp",
  components: {

  },
  data() {
    return {
      newYearLuckyInfo: null,
      isInApp: false,
    }
  },
  created() {
    let { token, inapp } = this.$route.query;
    if (token) {
      this.isInApp = true;
      replaceAppToken(token, () => {
        this.Init()
      });
    } else {
      if (inapp === '1') this.isInApp = true;
      this.Init()
    }
  },
  methods: {
    Init() {
      this.$toast.loading({ message: '加载中...', duration: 0, mask: true });
      this.post('/PersonCenter/TeamAgent/GetAccountBalance', {
        brandId: 3
      }, true, 2).then(json => {
        this.$toast.clear();
        if (json.code === 1) {
          this.newYearLuckyInfo = json.response.orderLuckydrawNum2;
        } else {
          return this.$dialog.alert({ title: '提示', message: json.msg }).then(() => {
            if (this.isInApp) deliveryMessage({ 'action': 'goHome' })
            else this.$router.push("/")
          })
        }
      })
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss">
.ivv-layer .content {
  // min-width: 80%;
  // max-height: 80%;
  // overflow-y: auto;
  // text-align: center;
  // position: relative;
  // border-radius: 6px;
  // background-color: #fff;
}

.ivv-layer .content .title {
  color: #666;
  font-size: 16px;
  font-weight: bold;
  line-height: 48px;
  letter-spacing: 1px;
}

.ivv-layer .content .line {
  padding-bottom: 5px;
  height: 1px;
  border-top: 1px solid #f1f1f1;
}

.ivv-layer .content .prompt {
  color: #666;
  font-size: 12px;
  letter-spacing: 1px;
}

.ivv-layer .content .count {
  color: #ff98a0;
  padding: 11px 0;
}

.ivv-layer .content .count span {
  color: #ff98a0;
  font-size: 24px;
}

.ivv-layer .content > img {
  width: 310px;
}

.ivv-layer .content .icon-ivv-close {
  color: #666;
  position: absolute;
  top: 15px;
  right: 15px;
}

.ivv-layer .content .icon-ivv-close:before {
  content: '\e9d6';
  font-size: 24px;
}
.ivv-layer .content.years-cont {
  border: 10px solid #fa1313;
}
.ivv-layer .content.years-cont .hint {
  font-size: 10px;
  color: #fa1313;
  padding: 10px 0 10px;
}
.years-cont .y-list {
  text-align: left;
  padding: 0 15px;
}
.years-cont .y-list span {
  font-size: 14px;
}
.years-cont .y-list .li {
  margin-bottom: 10px;
}
.years-cont .y-list .li p {
  font-size: 12px;
  margin-bottom: 0;
}
.years-cont .y-list .li p em {
  font-size: 16px;
  font-weight: bold;
  color: #fa1313;
  font-style: normal;
}
.years-cont .y-list .li p em.rig {
  padding-left: 10px;
}
</style>